import React from 'react'
import styled from 'styled-components'
import Image from 'components/Image'

// ===================================================
// STYLES
// ===================================================

const Container = styled('div')({
  position: 'relative',
  width: '100%',
  height: ({ aspectRatio }) => (aspectRatio === 'natural' ? 'initial' : 0),
  paddingBottom: ({ aspectRatio }) => aspectRatio,
})

// ===================================================
// COMPONENTS
// ===================================================

const StoryblokBannerImage = ({ data: { image, aspectRatio } }) => (
  <Container aspectRatio={aspectRatio}>
    <Image
      background={aspectRatio !== 'natural'}
      src={image?.filename}
      alt={image?.alt}
    />
  </Container>
)

export default StoryblokBannerImage
